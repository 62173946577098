import { ModalHeader, useBodyScrollLockCallbackRef } from '@loveholidays/design-system';
import { TFunction, useTranslation } from '@loveholidays/phrasebook';
import React, { useRef, ReactNode, Fragment, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useClient } from 'urql';

import { SelectFavouritesList } from './SelectFavouritesList';
import createFavouritesListMutation from '../graphql/createFavouritesList.gql';
import { useUnauthenticatedRedirect } from '../useUnauthenticatedRedirect';
import { isUnauthenticatedError } from '../utils';
import { Mutation } from '@AuroraTypes';
import { isAnyDestination } from '@Client/utils/isAnyDestination';
import { Button } from '@Components/Button/Button';
import { ListNameInput } from '@Components/LayoutComponents/FavouritesListDetailsPageComponent/ListNameInput';
import { LoadableModal } from '@Components/Modal/LoadableModal';
import { useModal } from '@Components/Modal/useModal';
import { useAppContext } from '@Contexts/contexts';
import { usePathTemplate } from '@Core/usePathTemplate';
import { SearchAvailabilityStore } from '@Stores/SearchAvailabilityStore';
import { SearchSelectionStore, isFamily } from '@Stores/SearchSelectionStore';
import { useFavouritesStore, useStoreContext } from '@Stores/StoreContext';

export enum TypeFavouritesModal {
  Add = 'add-to-favourites',
  Create = 'create-favourites',
}

interface FavouritesModalProps {
  children: (setModalOpen: () => void) => ReactNode;
  masterId?: number;
  type: TypeFavouritesModal;
}

const getDefaultTitle = (
  t: TFunction,
  availability: SearchAvailabilityStore,
  selection: SearchSelectionStore,
) => {
  const { date, destinationIds, rooms } = selection;

  const destinations = destinationIds
    .filter((id) => !isAnyDestination(id)) // remove "Any Destination"
    .map((id) => availability.destinations.find((d) => d.id === id)?.name);
  const hasDestinations = !!destinations.length;
  const hasDate = !!date;
  const isFamilyTrip = isFamily(rooms);

  if (!hasDestinations && !hasDate && !isFamily) {
    return t('favourites.myNewTrip');
  }

  return [
    isFamilyTrip ? t('favourites.familyTrip') : t('favourites.trip'),
    hasDestinations && `to ${destinations.join(', ')}`,
    hasDate && `in ${date!.format('MMMM YYYY')}`,
  ]
    .filter(Boolean)
    .join(' ');
};

export const FavouritesModal: React.FC<FavouritesModalProps> = ({ children, masterId, type }) => {
  const { t } = useTranslation();
  const urqlClient = useClient();
  const { isWebView } = useAppContext();
  const [loading, setLoading] = useState(false);
  const scollLockRef = useRef(null);
  const setRef = useBodyScrollLockCallbackRef(scollLockRef);
  const [isModalOpen, setModalOpen, setModalClose] = useModal();
  const [currentType, setNewType] = useState(type);
  const redirectToLoginPage = useUnauthenticatedRedirect();
  const history = useHistory();
  const favouritesPath = usePathTemplate('favourites');

  const { searchAvailability, searchSelection } = useStoreContext();
  const createFavouritesList = useFavouritesStore((state) => state.createFavouritesList);
  const [isValid, setIsValid] = useState(true);
  const [title, setTitle] = useState(
    getDefaultTitle(t, searchAvailability.getState(), searchSelection.getState()),
  );

  const closeModal = useCallback(() => {
    setNewType(type);
    setModalClose();
  }, [setModalClose, type]);

  const handleCreateFavouritesList = async () => {
    setLoading(true);

    const { data, error } = await urqlClient
      .mutation<Mutation>(createFavouritesListMutation, {
        input: {
          name: title,
          searchQuery: searchSelection.getState().toUrl('favourites'),
          ...(masterId && { masterId }),
        },
      })
      .toPromise();

    const newFavouritesList = data?.User?.createFavouritesListV2;

    if (isUnauthenticatedError(newFavouritesList)) {
      return redirectToLoginPage();
    }

    if (error || !newFavouritesList) {
      setLoading(false);
      throw new Error('Failed to create new favourites list');
    }

    createFavouritesList({
      id: newFavouritesList.id,
      title: newFavouritesList.name,
      search: newFavouritesList.searchQuery,
      items: newFavouritesList.masterIds,
    });

    setLoading(false);

    if (type === TypeFavouritesModal.Add) {
      setNewType(type);
    } else {
      closeModal();
      history.push(`${favouritesPath}${newFavouritesList.id}/`);
    }
  };

  return (
    <Fragment>
      {children(setModalOpen)}
      <LoadableModal
        show={isModalOpen}
        onClose={closeModal}
        mobileFloating={!isWebView}
        Header={
          <ModalHeader
            layout="medium"
            label={
              currentType === TypeFavouritesModal.Add
                ? t('favourites.changeList')
                : t('favourites.createANewList')
            }
            onClose={closeModal}
          />
        }
        Content={
          <Fragment>
            {currentType === TypeFavouritesModal.Add && masterId && (
              <div
                ref={setRef}
                sx={{
                  width: '100%',
                  paddingBottom: 's',
                }}
                data-id="add-to-favourites-modal"
              >
                <SelectFavouritesList
                  masterId={masterId}
                  onCreateFavourites={() => {
                    setNewType(TypeFavouritesModal.Create);
                  }}
                />
              </div>
            )}
            {currentType === TypeFavouritesModal.Create && (
              <div
                ref={setRef}
                sx={{
                  width: '100%',
                  paddingTop: 's',
                  paddingBottom: '2xl',
                }}
                data-id="create-favourites-modal"
              >
                <ListNameInput
                  labelTitle={t('favourites.name')}
                  title={title}
                  onChange={({ title, isValid }) => {
                    setTitle(title);
                    setIsValid(isValid);
                  }}
                  onEnterKeyDown={handleCreateFavouritesList}
                />
              </div>
            )}
          </Fragment>
        }
        Actions={
          <Fragment>
            {currentType === TypeFavouritesModal.Add && (
              <Button
                data-id="done-btn"
                variant="PrimaryDefault"
                size="48"
                onClick={setModalClose}
              >
                {t('done')}
              </Button>
            )}
            {currentType === TypeFavouritesModal.Create && (
              <Button
                size="48"
                variant="PrimaryDefault"
                disabled={!isValid}
                loading={loading}
                onClick={handleCreateFavouritesList}
              >
                {t('done')}
              </Button>
            )}
          </Fragment>
        }
      />
    </Fragment>
  );
};
