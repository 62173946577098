import { Icon, Image, ResponsiveImageSize } from '@loveholidays/design-system';
import React, { useMemo } from 'react';
import { SxStyleProp } from 'theme-ui';
import { useQuery } from 'urql';

import getHotelImages from './getHotelImages.gql';
import { Query, Image as ImageType } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { isQueryResolved } from '@Core/isQueryResolved';

export interface CombinedHotelImageProps extends ClassNameProps {
  masterIds: number[];
  width: ResponsiveImageSize;
  height: ResponsiveImageSize;
  compact?: boolean;
}

// Grid styles for 1/2/3/4 images
const gridStyles: ((compact: boolean) => SxStyleProp)[] = [
  // 1 image
  () => ({
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr',
  }),
  // 2 images
  (compact) => ({
    gridTemplateColumns: compact ? '1fr 1fr' : ['1fr 1fr', '1fr 2fr'],
    gridTemplateRows: '2fr 2fr',
  }),
  // 3 images
  (compact) => ({
    gridTemplateColumns: compact ? '1fr 1fr' : ['1fr 1fr', '1fr 2fr'],
    gridTemplateRows: '1fr 1fr',
    '> div': {
      paddingBottom: 0,
      height: '100%',
    },
    '> div:nth-of-type(3)': {
      gridColumnStart: 2,
      gridRowStart: 1,
      gridRowEnd: 3,
    },
    '> div:nth-of-type(1)': {
      gridColumnStart: 1,
      gridRowStart: 2,
    },
  }),
  // 4 images
  () => ({
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '2fr 2fr',
    '> div': {
      paddingBottom: 0,
      height: 'auto',
    },
    '> div:nth-of-type(1)': {
      gridColumnStart: 1,
      gridRowStart: 2,
    },
    '> div:nth-of-type(2)': {
      gridColumnStart: 1,
      gridRowStart: 1,
    },
    '> div:nth-of-type(3)': {
      gridColumnStart: 2,
      gridRowStart: 1,
    },
    '> div:nth-of-type(4)': {
      gridColumnStart: 2,
      gridRowStart: 2,
    },
  }),
];

/**
 * Generates a combined hotel image for max 4 hotels.
 */
export const CombinedHotelImage: React.FC<CombinedHotelImageProps> = ({
  masterIds,
  width,
  height,
  compact = false,
  className,
}) => {
  const [{ error, data, fetching }] = useQuery<Query>({
    query: getHotelImages,
    variables: {
      ids: masterIds.slice(-4),
    },
    pause: !masterIds.length,
  });

  const images =
    !masterIds.length || error || !isQueryResolved<Query>(fetching, data)
      ? []
      : data.Content.accommodations
          .reduce((acc, hotel) => {
            acc.push(...hotel.images.items);

            return acc;
          }, [] as ImageType[])
          .slice(-4);
  const imagesJson = JSON.stringify(images);

  return useMemo(
    () => (
      <div
        className={className}
        sx={{
          height,
          width: '100%',
          overflow: 'hidden',
          borderRadius: '12',
          ...(images.length
            ? {
                display: 'grid',
                gap: '5xs',
                ...gridStyles[images.length - 1](compact),
              }
            : {
                backgroundColor: 'backgroundLightsubtle',
              }),
        }}
      >
        {masterIds.length === 0 && (
          <div
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              borderRadius: '12',
              overflow: 'hidden',
              justifyContent: 'center',
            }}
          >
            <Icon
              name="Content/MediaImage"
              size={compact ? '24' : ['24', '48']}
              color="textDimmedmedium"
            />
          </div>
        )}
        {images.map((image) => (
          <Image
            key={image.url}
            src={image.url}
            alt={image.description}
            width={width}
            height={height}
            sx={{
              backgroundColor: 'backgroundLightsubtle',
            }}
          />
        ))}
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [imagesJson, masterIds],
  );
};
