import { Icon, Heading } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import { MOBILE_SIZE, DESKTOP_SIZE } from './FavouritesList';
import { Card } from '@DesignSystemComponents/Card/Card';

export interface SelectFavouritesListProps {
  compact?: boolean;
}

export const FavouriteNewList: React.FC<SelectFavouritesListProps> = ({
  compact = false,
  ...restProps
}) => {
  const { t } = useTranslation();

  return (
    <Card
      variant={compact ? 'SmallWide' : 'ResponsiveTallToWide'}
      floating
      images={[]}
      imageComponent={
        <div
          sx={{
            height: compact ? MOBILE_SIZE : [MOBILE_SIZE, DESKTOP_SIZE, DESKTOP_SIZE],
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'backgroundLightsubtle',
            color: 'white',
            borderRadius: '12',
          }}
        >
          <Icon
            name="Actions/AdjustAdd"
            size={compact ? '24' : ['24', '48']}
            color="textDimmedmedium"
          />
        </div>
      }
      height={compact ? MOBILE_SIZE : [MOBILE_SIZE, DESKTOP_SIZE, DESKTOP_SIZE]}
      sx={{
        width: '100%',
        '> div:first-of-type': {
          flex: `0 0 ${MOBILE_SIZE}px`,
        },
        '> div:nth-of-type(2)': {
          paddingY: [0, 'xs'],
        },
        ...(compact && {
          '> div:first-of-type': {
            flex: `0 0 ${MOBILE_SIZE}px`,
          },
          '> div:nth-of-type(2)': {
            paddingY: 0,
          },
        }),
      }}
      {...restProps}
    >
      <Heading
        as="h3"
        variant={compact ? 'smallbold' : ['smallbold', 'mediumbold']}
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          marginBottom: [null, '3xs'],
          wordBreak: [null, 'break-word'],
          ...(compact && {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          }),
        }}
      >
        {t('favourites.createANewList')}
      </Heading>
    </Card>
  );
};
